import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { GalleryType } from './galleryType/GalleryType'

import * as S from './styles'

export const Gallery = () => (
  <StaticQuery
    query={graphql`
      {
        allContentfulGallery {
          nodes {
            type
            imageBig {
              fluid {
                ...GatsbyContentfulFluid_noBase64
              }
            }
            imageBigAlt
            imageSmallOne {
              fluid {
                ...GatsbyContentfulFluid_noBase64
              }
            }
            imageSmallOneAlt
            imageSmallTwo {
              fluid {
                ...GatsbyContentfulFluid_noBase64
              }
            }
            imageSmallTwoAlt
            imageSmallThree {
              fluid {
                ...GatsbyContentfulFluid_noBase64
              }
            }
            imageSmallThreeAlt
            imageSmallFour {
              fluid {
                ...GatsbyContentfulFluid_noBase64
              }
            }
            imageSmallFourAlt
          }
        }
      }
    `}
    render={data => {
      const GALLERY_OBJECTS = data.allContentfulGallery.nodes
      return (
        <S.Wrapper as='section'>
          <S.Heading as='h2' h2>
            Galeria
          </S.Heading>
          {GALLERY_OBJECTS.map((gallery, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <GalleryType key={i} gallery={gallery} />
          ))}
        </S.Wrapper>
      )
    }}
  />
)
