import styled from 'styled-components'
import Img from 'gatsby-image'

import { device } from 'src/themes/breakpoints'

export const Container = styled.div`
  margin-bottom: 1.6rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 1.6rem;
  grid-row-gap: 1.6rem;

  @media ${device.mobileXl} {
    grid-template-columns: 2fr repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`

export const ImageBig = styled.div`
  height: 100%;
  grid-area: 1 / 1 / 2 / 3;

  @media ${device.mobileXl} {
    grid-area: 1 / 1 / 3 / 2;
  }
`

export const ImageOne = styled.div`
  grid-area: 2 / 1 / 3 / 2;

  @media ${device.mobileXl} {
    grid-area: 1 / 2 / 2 / 3;
  }
`

export const ImageTwo = styled.div`
  grid-area: 2 / 2 / 3 / 3;

  @media ${device.mobileXl} {
    grid-area: 1 / 3 / 2 / 4;
  }
`

export const ImageThree = styled.div`
  grid-area: 3 / 1 / 4 / 2;

  @media ${device.mobileXl} {
    grid-area: 2 / 2 / 3 / 3;
  }
`

export const ImageFour = styled.div`
  grid-area: 3 / 2 / 4 / 3;

  @media ${device.mobileXl} {
    grid-area: 2 / 3 / 3 / 4;
  }
`

export const Image = styled(Img)`
  height: 100%;
`
