import styled from 'styled-components'
import Img from 'gatsby-image'

import { Wrapper as WrapperComponent } from 'src/components/common/wrapper/Wrapper'
import { Heading as HeadingComponent } from 'src/components/common/heading/Heading'
import { Paragraph as ParagraphComponent } from 'src/components/common/paragraph/Paragraph'
import { Button as ButtonComponent } from 'src/components/common/button/Button'

import { device } from 'src/themes/breakpoints'

export const Container = styled.section`
  padding-top: 8rem;
  padding-bottom: 2.5rem;
  position: relative;
  background: ${({ theme }) => theme.colors.bgGray};

  @media ${device.tablet} {
    padding-top: 12rem;
  }
`

export const Wrapper = styled(WrapperComponent)`
  @media ${device.mobileXl} {
    display: flex;
    align-items: center;
  }
`

export const DetailsBox = styled.div`
  @media ${device.mobileXl} {
    flex-basis: 50%;
    padding-right: 2rem;
  }

  @media ${device.laptop} {
    flex-basis: 40%;
  }
`

export const Heading = styled(HeadingComponent)``

export const Paragraph = styled(ParagraphComponent)`
  margin: 3rem 0 2.7rem;
`

export const Button = styled(ButtonComponent)`
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: 4.5rem;
  transform: translateX(-50%);

  @media ${device.mobileXl} {
    position: static;
    z-index: unset;
    left: unset;
    bottom: unset;
  }
`

export const MobileImage = styled(Img)`
  position: relative;
  max-height: 44rem;

  @media ${device.mobileXl} {
    display: none;
  }
`

export const ImagesBox = styled.div`
  display: none;

  @media ${device.mobileXl} {
    position: relative;
    display: block;
    flex-basis: 50%;
    display: flex;
    flex-wrap: wrap;
  }

  @media ${device.mobileXl} {
    flex-basis: 60%;
  }
`

export const DesktopImage = styled(Img)`
  flex-basis: 50%;
  max-height: 33rem;
`
