import styled from 'styled-components'

import { Wrapper as WrapperComponent } from 'src/components/common/wrapper/Wrapper'
import { Heading as HeadingComponent } from 'src/components/common/heading/Heading'

export const Wrapper = styled(WrapperComponent)`
  padding-top: 5rem;
  padding-bottom: 3.4rem;
`

export const Heading = styled(HeadingComponent)`
  text-align: center;
  margin-bottom: 3rem;
`
