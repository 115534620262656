import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

export const TypeFour = props => {
  const { imageSmallOne, imageSmallTwo, imageSmallThree, imageSmallFour } = props
  return (
    <S.Container>
      <S.ImageOne>
        <S.Image fluid={imageSmallOne.fluid} />
      </S.ImageOne>
      <S.ImageTwo>
        <S.Image fluid={imageSmallTwo.fluid} />
      </S.ImageTwo>
      <S.ImageThree>
        <S.Image fluid={imageSmallThree.fluid} />
      </S.ImageThree>
      <S.ImageFour>
        <S.Image fluid={imageSmallFour.fluid} />
      </S.ImageFour>
    </S.Container>
  )
}

TypeFour.propTypes = {
  imageSmallOne: PropTypes.shape({
    id: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      sizes: PropTypes.string
    })
  }).isRequired,
  imageSmallTwo: PropTypes.shape({
    id: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      sizes: PropTypes.string
    })
  }).isRequired,
  imageSmallThree: PropTypes.shape({
    id: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      sizes: PropTypes.string
    })
  }).isRequired,
  imageSmallFour: PropTypes.shape({
    id: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      sizes: PropTypes.string
    })
  }).isRequired
}
