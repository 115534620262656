import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

export const TypeThree = props => {
  const { imageBig, imageSmallOne, imageSmallTwo } = props
  return (
    <S.Container>
      <S.ImageOne>
        <S.Image fluid={imageSmallOne.fluid} />
      </S.ImageOne>
      <S.ImageTwo>
        <S.Image fluid={imageSmallTwo.fluid} />
      </S.ImageTwo>
      <S.ImageBig>
        <S.Image fluid={imageBig.fluid} />
      </S.ImageBig>
    </S.Container>
  )
}

TypeThree.propTypes = {
  imageBig: PropTypes.shape({
    id: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      sizes: PropTypes.string
    })
  }).isRequired,
  imageSmallOne: PropTypes.shape({
    id: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      sizes: PropTypes.string
    })
  }).isRequired,
  imageSmallTwo: PropTypes.shape({
    id: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      sizes: PropTypes.string
    })
  }).isRequired
}
