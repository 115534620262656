import styled from 'styled-components'
import Img from 'gatsby-image'

export const Container = styled.div`
  margin-bottom: 1.6rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1.6rem;
  grid-row-gap: 1.6rem;
`

export const ImageOne = styled.div`
  grid-area: 1 / 1 / 2 / 2;
`

export const ImageTwo = styled.div`
  grid-area: 1 / 2 / 2 / 3;
`

export const ImageThree = styled.div`
  grid-area: 1 / 3 / 2 / 4;
`

export const ImageFour = styled.div`
  grid-area: 1 / 4 / 2 / 5;
`

export const Image = styled(Img)`
  height: 100%;
`
