import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import * as S from './styles'

export const Main = () => (
  <StaticQuery
    query={graphql`
      {
        contentfulGalleryMain {
          mainHeader
          mainParagraph
          imageOne {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          imageTwo {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          imageThree {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          imageFour {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const { mainHeader, mainParagraph, imageOne, imageTwo, imageThree, imageFour } = data.contentfulGalleryMain
      return (
        <S.Container>
          <S.Wrapper>
            <S.DetailsBox>
              <S.Heading>{mainHeader}</S.Heading>
              <S.Paragraph>{mainParagraph}</S.Paragraph>
              <S.Button to='/'>Więcej &gt;</S.Button>
              <S.MobileImage fluid={imageOne.fluid} />
            </S.DetailsBox>
            <S.ImagesBox>
              <S.DesktopImage fluid={imageOne.fluid} />
              <S.DesktopImage fluid={imageTwo.fluid} />
              <S.DesktopImage fluid={imageThree.fluid} />
              <S.DesktopImage fluid={imageFour.fluid} />
            </S.ImagesBox>
          </S.Wrapper>
        </S.Container>
      )
    }}
  />
)
