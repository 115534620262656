import React from 'react'

import { MainTemplate } from 'src/components/templates/MainTemplate'
import { Main } from 'src/components/gallery/main/Main'
import { Gallery } from 'src/components/gallery/gallery/Gallery'

import SEO from '../components/seo/seo'

const GalleryPage = () => (
  <MainTemplate>
    <SEO description='dsafagaeggr' title='Maszyny stworzone przez nas | Kuliberda.tech' />
    <Main />
    <Gallery />
  </MainTemplate>
)

export default GalleryPage
