import React from 'react'
import PropTypes from 'prop-types'

import { TypeFive } from './typeFive/TypeFive'
import { TypeFour } from './typeFour/TypeFour'
import { TypeThree } from './typeThree/TypeThree'

export const GalleryType = ({ gallery }) => {
  if (gallery.type === 'five') return <TypeFive {...gallery} />
  if (gallery.type === 'four') return <TypeFour {...gallery} />
  return <TypeThree {...gallery} />
}

GalleryType.propTypes = {
  gallery: PropTypes.shape({
    type: PropTypes.string,
    imageBig: PropTypes.shape({
      id: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string
      })
    }),
    imageBigAlt: PropTypes.string,
    imageSmallOne: PropTypes.shape({
      id: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string
      })
    }),
    imageSmallOneAlt: PropTypes.string,
    imageSmallTwo: PropTypes.shape({
      id: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string
      })
    }),
    imageSmallTwoAlt: PropTypes.string,
    imageSmallThree: PropTypes.shape({
      id: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string
      })
    }),
    imageSmallThreeAlt: PropTypes.string,
    imageSmallFour: PropTypes.shape({
      id: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string
      })
    }),
    imageSmallFourAlt: PropTypes.string
  }).isRequired
}
